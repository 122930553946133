<template>
  <v-footer
    dark
    color="secondary"
    class="footer-mobile"
  >
    <div class="footer__banner" />
    <div class="footer__bottom text-white">
      <div
        v-if="!isDiscontinued"
        class="mb-5"
      >
        <strong>Jeu avec obligation d'achat.</strong>
        <br />
        Valable du 12/08/2024 au 13/10/2024.
      </div>
      <div class="mb-5">
        <strong>Offre Boutique avec obligation d'achat.</strong>
        <br />
        Valable du 18/03/2024 au 31/12/2024.
      </div>
      <v-divider
        color="tertiary"
        class="mb-5 border-opacity-100"
      />
      <div class="text-tertiary">
        Pour votre santé, mangez au moins cinq fruits et légumes par jour.
        <a
          href="https://www.mangerbouger.fr"
          target="_blank"
        >
          www.mangerbouger.fr
        </a>
      </div>
    </div>
  </v-footer>
</template>

<script setup lang="ts">
const { isDiscontinued } = useGame2()
</script>

<style lang="scss" scoped>
$background-image-width: 1440px;
$background-image-height: 40px;

.footer-mobile {
  flex-grow: 0;
  padding: 0;
  flex-direction: column;
}
.footer__banner {
  width: 100%;
  height: calc($background-image-height / 2);
  background-image: url('~/assets/images/background-footer.png');
  background-position: left top;
  background-size: calc($background-image-width / 2)
    calc($background-image-height / 2);
  background-repeat: repeat-x;
}

.footer__bottom {
  padding: 20px 20px 140px;

  font-size: 14px;
  line-height: 16px;

  margin: 0 0 30px 0;

  a {
    color: inherit;
  }
}
</style>
